import React, { useEffect } from 'react'
import { Grid, Row } from 'atomic'
import { Query } from '@root/src/data/graphql/graphql-types'
import { graphql } from 'gatsby'
import { useMediaQuery, useTheme } from '@material-ui/core'
//import Title from '@root/src/components/Title'
import { ExamDetailContentRow } from '@root/src/modules/exam/component/exam-detail-content-row.component'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { PageProps } from '@app/utils/local-types'
import { doctorUrlPrefix } from 'utils/url'
import { SEO } from '@root/src/components/legacy/mol.seo/seo.component'
import { getCanonicalUrl, appPaths } from 'utils/path'
import { MainTitle } from '@root/src/components/Typography'
import { navigate } from 'gatsby'

export interface ExamDetailTemplateProps {
  slug: string
}

const ExamDetailTemplate: React.FunctionComponent<PageProps<
  ExamDetailTemplateProps,
  Query
>> = props => {
  const data = props.data.exames

  const themeMui = useTheme()
  const downOfMd = useMediaQuery(themeMui.breakpoints.down('sm'))
  const adjustMarginTop = downOfMd
    ? process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann'
      ? 100
      : 0
    : 0

  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[`/${props.pageContext.slug}`] = data.nome

  // TODO: EXAME - na resposta vem array
  const synonyms = data.sinonimias

  // *********
  // filtro temporário para os exames 9056, 9057 e 9058, 
  // deverá ser removido quando as informações forem corrigidas 
  // *********
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (props.data.exames.idLegado === 9056 
        || props.data.exames.idLegado === 9057 
        || props.data.exames.idLegado === 9058) 
      { 
        navigate('/') 
      }
    }
  }, [])

  return (
    <ClientDoctorSelectContext.Provider
      value={{
        clientUrl: `/exames/${props.pageContext.slug}`,
        doctorUrl: `/${doctorUrlPrefix}/exames/${props.pageContext.slug}`
      }}
    >
      <IndexLayout location={props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(
              props.data.site.siteMetadata.siteUrl,
              appPaths.exams.path,
              data.slug
            ),
            title: data.seo.titulo,
            image: data.seo.imagem.url,
            imageAlt: data.seo.imagemAlternativa,
            description: data.seo.descricao
          }}
        />
        <Grid style={{ marginTop: adjustMarginTop }}>
          <TitleWithBreadcrumbRow addtionalDictionary={breadcrumbAdditionalDictionary} />
          <Row style={{ marginLeft: '2px', marginTop: '-24px', marginBottom: '24px' }}>
            <MainTitle variant="h5">Instrução de preparo</MainTitle>
          </Row>
          <ExamDetailContentRow
            location={props.location}
            topics={data.instrucoesGerais}
            cosmicjsInformacoesEstaticas={props.data.cosmicjsInformacoesEstaticas}
            about={data.sobre}
            examTitle={data.nome}
            infoEstat={props.data.cosmicjsInformacoesEstaticas}
            idLegado={data.idLegado}
            nome={data.nome}
            sigla={data.sigla}
            instrucoesGerais={data.instrucoesGerais}
            sinonimias={data.sinonimias}
            unidades={data.unidades}
            precisaAgendar={data.precisaAgendar}
            seoCosmic={data.seo}
            units={data.unidades}
            siteUrl={props.data.site.siteMetadata.siteUrl}
            slug={props.pageContext.slug}
            synonyms={synonyms}
          />
        </Grid>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default ExamDetailTemplate

export const query = graphql`
  query ExamDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    exames(slug: { eq: $slug }) {
      id
      idLegado
      nome
      sobre
      sigla
      slug
      precisaAgendar
      sinonimias
      unidades {
        title
        slug
        idLegado
        nome
        endereco
        bairro
        cidade
        cep
        estado
        fotos {
          imagem {
            url
            imgix_url
          }
        }
      }
      seo {
        titulo
        descricao
        imagemAlternativa
        imagem {
          url
        }
      }
      instrucoesGerais {
        titulo
        conteudo
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "detalhe-do-exame" }) {
      metadata {
        examesTituloConvenio
        examesConteudoConvenio
        examesTituloPreco
        examesConteudoPreco
        emailIntroMsg
        emailModalTitulo
        emailModalCorpo
        examesBotaoAgendar
        examesBotaoSaibaMais
        informacoes_adicionais {
          titulo_card
          informacoes_card
          texto_botao_card
          acao_botao_card
        }
      }
    }
  }
`
